<style scoped>

.experience:hover {
  background-color: #e83737 !important;
}

.download:hover {
  background-color: #1C232B !important;
  border: 1px solid white;
}

.zoom-animation {
  animation: zoom 1.1s infinite alternate;
}

@keyframes zoom {
  from {
    transform: scale(1); /* 起始时大小不变 */
  }
  to {
    transform: scale(1.1); /* 结束时放大到1.5倍 */
  }
}

.buy-button {
  width: 220px;
  height: 35px;
  font-size: 16px;
  margin-top: 30px;
  background-color: #3E444B;
  color: white;
}

.buy-button:hover {
  background-color: red;
  color: white;
}


.dian {
  font-size: 18px;
  display: inline-block;
  margin-bottom: 7px;
}

.text-wrapper {
  position: absolute; /* 绝对定位允许我们覆盖在其他元素上 */
  top: 180px; /* 调整以匹配图像的位置 */
  left: -180px; /* 或者你可以使用其他值来将文本放置在图像的特定部分上 */
  display: flex; /* 使用Flexbox来居中文本，但这不是必需的 */
}

.big-picture {
  font-size: 20px;
  color: #CCCCCC;
}
</style>

<template>

  <div style="background-color: #1C232B;padding: 1px">
    <!--  第一个大容器-->
    <div style="text-align: center;">
      <div style="margin-left: 19%;">

        <div style="height: 900px;padding: 1px;margin-top: 150px;">

          <div style="width: 1000px;float: left;text-align: left;">
            <div style="color: white;">
              <div style="height: 300px;">
                <!--<span style="font-size: 94px;letter-spacing: 10px">{{ t('home.LinYun') }}</span>-->
                <img :src="t('home.img')" alt="" style="height: 50%">

                <div style="margin-top: 70px;float: left;">
                  <span style="font-size: 40px;">{{ t('home.Brief1') }}</span>
                </div>
              </div>
            </div>

            <div style="color: #CCCCCC;font-size: 20px;float: left;line-height: 35px">
              <span>{{ t('home.Brief2') }}</span>
              <br>
              <span>{{ t('home.Brief3') }}</span>
              <br>
              <span>{{ t('home.Brief4') }}</span>
            </div>
          </div>

          <div style="margin-top: 530px;">
            <div>
              <h2 style="float: left;color: white;font-size: 20px">
                {{ t('home.ReleaseDate') }}
              </h2>
            </div>

            <div style="float: right;margin-top: 15px;margin-right: 18%">
              <router-link to="/download">
                <el-button type="danger"
                           style="font-size: 20px;height: 50px;background-color: #e83737;border-radius: 10px;"
                           class="download">{{ t('home.FreeDownload') }}
                </el-button>
              </router-link>

<!--              <router-link to="/experienceFeedback">-->
                <el-button type="info" style="font-size: 20px;height: 50px;background-color: #1C232B;margin-left: 30px;
            border-radius: 10px" class="experience">{{ t('home.ExperienceFeedback') }}
                </el-button>
<!--              </router-link>-->

            </div>
          </div>

        </div>
      </div>
    </div>


    <!--  第二个大容器-->
    <div style="height: 350px;">
      <span style="font-size: 90px;color: white;margin-left: 30px">{{ t('home.UnityInDiversity') }}</span><br>
      <span style="font-size: 90px;color: white;margin-left: 50px">{{ t('home.QualityInDetails') }}</span><br><br>
      <span style="color: azure;font-size: 25px;">{{ t('home.LightWeight') }}</span>
    </div>
    <!--    第一张图片-->
    <img src="linYun1.png" alt="" style="height: 62.5%;width: 62.5%">

    <!--  第三个大容器-->
    <div style="height: 350px;margin-top: 200px;">
      <span style="font-size: 90px;color: white">{{ t('home.SmoothToStart') }}</span><br>
      <span style="font-size: 90px;color: white;">{{ t('home.SimpleButExceptional') }}</span><br><br>
      <span
          style="color: azure;font-size: 25px;">{{ t('home.SupportLarge') }}</span>
    </div>
    <!--    第二张图片-->
    <img src="linYun2.png" alt="" style="height: 62.5%;width: 62.5%">

    <!--  第四个大容器-->
    <div style="height: 350px;margin-top: 200px;">
      <span style="font-size: 90px;color: white">{{ t('home.CollaborationAndCommunication') }}</span><br>
      <span style="font-size: 90px;color: white;">{{ t('home.SeamlesslyAndEffortlessly') }}</span><br><br>
      <span style="color: azure;font-size: 25px;">{{ t('home.InAnAdvance') }}</span>
    </div>
    <!--    第三张图片-->
    <img src="linYun3.png" alt="" style="height: 62.5%;width: 62.5%">


    <!--  第五个大容器-->
    <div style="height: 350px;margin-top: 200px;">
      <span style="font-size: 90px;color: white">{{ t('home.NextGeneration') }}</span><br>
      <span style="font-size: 90px;color: white;">{{ t('home.BIMCloudPlatform') }}</span><br><br>
      <span style="color: azure;font-size: 25px;">{{ t('home.TheWorld') }}</span>
    </div>

    <div style="position: relative">
      <div style="width: 100%; height: 1250px;">
        <div style="width: 62.5%; height: 102%; margin: 0 auto; align-content: center">
          <img :src="t('home.img2')" alt="" style="width: 100%; height: 98%;">
        </div>
      </div>
    </div>

    <!--    第四张图片-->

    <!--    <div style="position: relative">-->
    <!--      <div style="width: 100%; height: 1250px;">-->
    <!--        <div style="width: 62.5%; height: 102%; margin: 0 auto; align-content: center">-->
    <!--          <img src="linYun4_C.png" alt="" style="width: 100%; height: 98%;">-->

    <!--          <div class="text-wrapper" style="margin-left: 15%;">-->
    <!--            <div>-->
    <!--              <img src="t_shiShi.png" alt="" style="height: 5%; margin-left: -11%">-->
    <!--              <div style="margin-left: 410px;margin-top: -40px;text-align: left">-->
    <!--                <span style="font-size: 22px;font-weight: bold;color: white">{{ t('home.DynamicRendering') }}</span>-->
    <!--              </div>-->
    <!--              <div style="border: 1px solid red;width: 350px;margin-left: 350px;margin-top: 40px;text-align: left">-->
    <!--              <span-->
    <!--                  class="big-picture">{{ t('home.BasedOnDynamic') }}</span>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="text-wrapper" style="margin-top: 280px;margin-left: 15%">-->
    <!--        <div>-->
    <!--          <img src="t_jianRong.png" alt="" style="height: 5%;margin-left: -11%;">-->
    <!--          <div style="margin-left: 415px;margin-top: -40px;border: 1px solid red;width: 250px;text-align: left">-->
    <!--                  <span-->
    <!--                      style="font-size: 22px;font-weight: bold;color: white">{{-->
    <!--                      t('home.PowerfulCompatibility')-->
    <!--                    }}</span>-->
    <!--          </div>-->
    <!--          <div style="border: 1px solid red;width: 350px;margin-left: 350px;margin-top: 40px;text-align: left">-->
    <!--                <span-->
    <!--                    class="big-picture">{{ t('home.UsingANewly') }}</span>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--      <div class="text-wrapper" style="margin-top: 530px;margin-left: 15%">-->
    <!--        <div>-->
    <!--          <img src="t_gaoPing.png" alt="" style="height: 5%;margin-left: -11%">-->
    <!--          <div style="margin-left: 415px;margin-top: -40px;border: 1px solid red;width: 250px;text-align: left">-->
    <!--            <span style="font-size: 22px;font-weight: bold;color: white">{{ t('home.HighQualityEffects') }}</span>-->
    <!--          </div>-->
    <!--          <div style="border: 1px solid red;width: 350px;margin-left: 350px;margin-top: 40px;text-align: left">-->
    <!--                <span-->
    <!--                    class="big-picture">{{ t('home.WithSimpleMaterial') }}</span>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->


    <!--      <div class="text-wrapper" style="margin-top: 800px;margin-left: 15%">-->
    <!--        <div>-->
    <!--          <img src="t_chaoDa.png" alt="" style="height: 5%;margin-left: -11%">-->
    <!--          <div style="margin-left: 415px;margin-top: -40px;border: 1px solid red;width: 270px;text-align: left">-->
    <!--                  <span style="font-size: 22px;font-weight: bold;color: white">{{-->
    <!--                      t('home.SupportForLargeModels')-->
    <!--                    }}</span>-->
    <!--          </div>-->
    <!--          <div style="border: 1px solid red;width: 350px;margin-left: 350px;margin-top: 40px;text-align: left">-->
    <!--                <span-->
    <!--                    class="big-picture">{{ t('home.NewGraphicsRendering') }}</span>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->


    <!--      <div class="text-wrapper" style="margin-left: 52%">-->
    <!--        <div>-->
    <!--          <img src="t_qingLiang.png" alt="" style="height: 5%;margin-left: -12%">-->
    <!--          <div style="margin-left: 410px;margin-top: -40px;border: 1px solid red;width: 250px;text-align: left">-->
    <!--            <span style="font-size: 22px;font-weight: bold;color: white">{{ t('home.ModelLightweight') }}</span>-->
    <!--          </div>-->
    <!--          <div style="border: 1px solid red;width: 350px;margin-left: 350px;margin-top: 40px;text-align: left">-->
    <!--                <span-->
    <!--                    class="big-picture">{{ t('home.SupportsModelLightweight') }}</span>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--      <div class="text-wrapper" style="margin-top: 280px;margin-left: 52%">-->
    <!--        <div>-->
    <!--          <img src="t_jianDan.png" alt="" style="height: 5%;margin-left: -12%">-->
    <!--          <div style="margin-left: 410px;margin-top: -40px;border: 1px solid red;width: 260px;text-align: left">-->
    <!--            <span style="font-size: 22px;font-weight: bold;color: white">{{ t('home.SmoothUserExperience') }}</span>-->
    <!--          </div>-->
    <!--          <div style="border: 1px solid red;width: 350px;margin-left: 350px;margin-top: 40px;text-align: left">-->
    <!--                <span-->
    <!--                    class="big-picture">{{ t('home.WithAMinimalist') }}</span>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--      <div class="text-wrapper" style="margin-top: 530px;margin-left: 52%">-->
    <!--        <div>-->
    <!--          <img src="t_shuJu.png" alt="" style="height: 5%;margin-left: -12%">-->
    <!--          <div style="margin-left: 410px;margin-top: -40px;border: 1px solid red;width: 250px;text-align: left">-->
    <!--            <span style="font-size: 22px;font-weight: bold;color: white">{{ t('home.InformationModel') }}</span>-->
    <!--          </div>-->
    <!--          <div style="border: 1px solid red;width: 350px;margin-left: 350px;margin-top: 40px;text-align: left">-->
    <!--                <span-->
    <!--                    class="big-picture">{{ t('home.SupportsTheIntegrationOf') }}</span>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--      <div class="text-wrapper" style="margin-top: 800px;margin-left: 52%">-->
    <!--        <div>-->
    <!--          <img src="t_jiCheng.png" alt="" style="height: 5%;margin-left: -12%">-->
    <!--          <div style="margin-left: 410px;margin-top: -40px;border: 1px solid red;width: 250px;text-align: left">-->
    <!--            <span style="font-size: 22px;font-weight: bold;color: white">{{ t('home.FlexibleIntegration') }}</span>-->
    <!--          </div>-->
    <!--          <div style="border: 1px solid red;width: 350px;margin-left: 350px;margin-top: 40px;text-align: left">-->
    <!--                <span-->
    <!--                    class="big-picture">{{ t('home.IndependentlyDeveloped') }}</span>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--  第六个大容器-->
    <div style="height: 550px;margin-top: 200px;"
         ref="workflow" id="workflow">
      <div  ref="download" id="download">
        <span style="font-size: 90px;color: white">{{ t('home.BetweenDifferentSoftware') }}</span><br>
        <span style="font-size: 90px;color: white;">{{ t('home.SeamlessConnection') }}</span><br><br>
        <span style="color: azure;font-size: 25px;margin-right: 75px">{{ t('home.InstallTheLinYun') }}</span>
      </div>

      <div style="margin-top: 80px;">
        <router-link to="/download" class="link">
        <el-button class="download" style="height: 50px;font-size: 23px;border-radius: 10px;background-color: #1C232B;color: white;
        margin-right: 50px;">{{ t('home.DownloadTheLinYunRevitPlugin') }}
        </el-button>
        </router-link>
      </div>

    </div>

    <div style="">
      <!--    第五张图片-->
      <img src="linYun5.png" alt="" style="height: 62.5%;width: 62.5%;position: relative">
      <img src="linYun6.png" alt="" class="zoom-animation" style="height: 25%; width: 13%; position: absolute;
      margin-left: -36%; margin-top: 10%;">
    </div>

    <!--  第七个大容器-->
    <div style="height: 1200px;margin-top: 350px;"
         ref="price" id="specific-section">
      <h1 style="font-size: 60px;color: white">{{ t('home.BuyNow') }}</h1>
      <div style="margin-top: -30px">
        <span style="color: azure;font-size: 25px;">{{ t('home.ChooseYourPreferredPlan') }}</span>
      </div>

      <div style="display: flex; justify-content: center; align-items: center;">
        <div style="margin-top: 50px;color: white;display: flex;">
          <!--购买条目-->
          <div style="width: 300px;height: 830px;background-color: #3E444B;border-radius: 10px;float: left">
            <h1 style="font-size: 35px">{{ t('home.TrialVersion') }}</h1>
            <span style="color: #CCCCCC;">{{ t('home.SuitableForBIMModelReporting') }}</span>
            <br>
            <div style="margin-right: 120px;margin-top: 10px;height: 90px">
              <span style="font-size: 54px;font-weight: bold">￥0</span>
              <br>
              <span>{{ t('home.FreeToUse') }}</span>
            </div>
            <el-button class="buy-button" @click="tryNow">{{ t('home.TryNow') }}</el-button>
            <div style="margin-left: -140px;margin-top: 30px;">
              <div style="width: 200px;text-align: left;margin-left: 155px">
                <h3 style="font-size: 20px">{{ t('home.TrialFeatures') }}</h3>
              </div>

              <div style="margin-left: 170px;text-align: left">
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.NumberOfLocalProjects') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.MultipleSoftwarePlugins') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.StorageCapacity') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.ViewpointFunction') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.SlicingFunction') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.MeasurementFunction') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.DirectoryTreeFunction') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.AttributeFunction') }}</span>
                <br>
              </div>
            </div>
          </div>

          <div
              style="width: 300px;height: 830px;background-color: #3E444B;border-radius: 10px;margin-left: 50px;float: left">
            <h1 style="font-size: 35px">{{ t('home.ProfessionalVersion') }}</h1>
            <span style="color: #CCCCCC;">{{ t('home.SuitableForPersonalProjects') }}</span>
            <br>
            <div style="margin-right:30px;margin-top: 10px;height: 90px">
              <span style="font-size: 54px;font-weight: bold">￥XXX</span>
              <span>/{{ t('home.Quarter') }}</span>
            </div>
<!--            <el-button class="buy-button" @click="buy">{{ t('home.ClickToUpgrade') }}</el-button>-->
<!--            <el-button class="buy-button">{{ t('home.ComingSoon') }}</el-button>-->
            <el-popover
                ref="popover"
                placement="right"
                :width="250"
                trigger="focus"
            >
              <template #reference>
                <el-button class="buy-button">{{ t('home.WeChatConsultation') }}</el-button>
              </template>
              <img src="/weixin.png" alt="">
            </el-popover>

            <div style="margin-left: -140px;margin-top: 30px;">
              <div style="width: 240px;text-align: left;margin-left: 155px">
                <h3 style="font-size: 20px">{{ t('home.ProfessionalFeatures') }}</h3>
              </div>
              <div style="margin-left: 170px;text-align: left">
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.NumberOfLocalProjectsProfessional') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.StorageCapacityProfessional') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.IssueCollaboration') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.ProjectDataMigration') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.ProfessionalTechnicalSupport') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.IncludesAllTrialVersionFeatures') }}</span>
                <br>
              </div>
            </div>
          </div>

          <div
              style="width: 300px;height: 830px;background-color: #3E444B;border-radius: 10px 0 0 10px;margin-left: 50px;float: left">
            <h1 style="font-size: 35px">{{ t('home.ProjectVersion') }}</h1>
            <span style="color: #CCCCCC;">{{ t('home.SuitableForSingleProjectUse') }}</span>
            <br>
            <div style="margin-right:30px;margin-top: 10px;height: 90px">
              <span style="font-size: 54px;font-weight: bold">￥XXX</span>
              <span>/{{ t('home.Year') }}</span>
            </div>
            <el-button class="buy-button">{{ t('home.ComingSoon') }}</el-button>
            <div style="margin-left: -140px;margin-top: 30px;">
              <div style="width: 200px;text-align: left;margin-left: 155px">
                <h3 style="font-size: 20px">{{ t('home.BasicConfiguration') }}</h3>
              </div>
              <div style="margin-left: 170px;text-align: left">
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.NumberOfCloudProjects') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.NumberOfAccounts') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.CloudStorageCapacity') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.CrossProjectCollaboration') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.IssueCollaborationProject') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.CloudBasedModel') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.CloudCompression') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.TechnicalSupport') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.IncludesAllProfessionalVersionFeatures') }}</span>
                <br>
              </div>
            </div>
          </div>

          <div style="width: 300px;height: 830px;background-color: #3E444B;border-radius: 0 10px 10px 0;float: left">
            <h1 style="font-size: 35px">{{ t('home.EnterpriseVersion') }}</h1>
            <span style="color: #CCCCCC;">{{ t('home.SuitableForProjectsOfVariousEnterpriseScales') }}</span>
            <br>
            <div style="margin-right:34px;margin-top: 10px;height: 90px;width: 250px;">
              <span style="font-size: 45px;font-weight: bold">{{ t('home.ContactUs') }}</span>
              <br>
              <div style="text-align: left;margin-left: 35px;margin-top: 7px">
                <span>{{ t('home.ProfessionalConfiguration') }}</span>
              </div>
            </div>
            <el-popover
                ref="popover"
                placement="right"
                :width="250"
                trigger="focus"
            >
              <template #reference>
                <el-button class="buy-button">{{ t('home.WeChatConsultation') }}</el-button>
              </template>
              <img src="/weixin.png" alt="">
            </el-popover>


            <div style="margin-left: -140px;margin-top: 30px;">
              <div style="width: 200px;text-align: left;margin-left: 155px">
                <h3 style="font-size: 20px">{{ t('home.ProfessionalConfiguration') }}</h3>
              </div>
              <div style="margin-left: 170px;text-align: left">
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.NumberOfCloudProjectsEnterprise') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.NumberOfAccountsEnterprise') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.CloudStorageCapacityEnterprise') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.CrossProjectCollaborationEnterprise') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.IssueCollaborationEnterprise') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.CloudBasedModelEnterprise') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.CloudCompressionEnterprise') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.EnterpriseCustomLOGO') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.SupportsPaidUpgradesForAccountsProjects') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.SupportsPaidCustomIntegration') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.TechnicalSupportEnterprise') }}</span>
                <br>
                <span class="dian">●</span>
                <span style="margin-left: 15px">{{ t('home.IncludesAllReleasedFeatures') }}</span>
                <br>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script lang="js" setup>
import {getCurrentInstance, nextTick, onMounted, reactive, ref, watch} from 'vue'
import axios from "axios";
import {ElMessage, ElMessageBox} from "element-plus";
import JSEncrypt from "jsencrypt";
import {useRoute} from 'vue-router';

import {useI18n} from 'vue-i18n';
const {proxy} = getCurrentInstance();

const {t} = useI18n();
// 当前语言的响应式变量

const route = useRoute();

const dialogFormVisible = ref(false)
const form = reactive({
  username: '',
  password: '',
  tenantName: '',
  tenantId: '',
})

const price = ref(null);
const priceRef = ref(null);
//点击价格下滑
const scrollToSpecificSection = () => {
  if (priceRef.value) {
    priceRef.value.scrollIntoView({behavior: 'smooth'});
  }
};


const workflow = ref(null);
const workflowRef = ref(null);
//点击工作流下滑
const scrollToSpecificSection2 = () => {
  if (workflowRef.value) {
    workflowRef.value.scrollIntoView({behavior: 'smooth'});
  }
};

const download = ref(null);
const downloadRef = ref(null);

const scrollToSpecificSection3 = () => {
  if (downloadRef.value) {
    downloadRef.value.scrollIntoView({behavior: 'smooth'});
  }
};

const local = ref();
const user = ref({
  userId: '',
  accessToken: '',
  username: '',
  password: '',
  tenantId: '',
  nickname: '',
  version: '',
})



const formLabelWidth = '100px';
const publicKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAn8wru5QjuYhj7pFNFdJroP5JmLQHbciRiIXrjlA4mfk+DFhJ8sPiiKQmZxDjfjip++tcChpKfhIqGrlVe3lJiojfV1wUaB' +
    'nf8GYc0+gJQuy1u94VYtwYC7Rdv/RIhMil2yiIsD+QX2c/gjEXxTXqO87KSNUdFJKCxHLUE3MY+6QAcV2WV4cp/z/x+BhYSZLG6+o8hKjyLtDMfTuJwZj2398QTcZ/6WlZWg2ScKUUxZX+BUj8GujT2jQk' +
    'WDMbvtLuBXT7KDR5OXo0cZNULAH9waaBd3rezzZCp8behKGPBeBbi2/CJRKcwDcJjzB51vrXwAW78mOvQ4m2UwS1culwcwIDAQAB'

const encryptor = new JSEncrypt();
encryptor.setPublicKey(publicKey);
const handleLogin = () => {
  axios.post('http://' + proxy.ip + ':48080/admin-api/system/auth/getTenant?username=' + form.username).then((response) => {
    if (response.data.code === 0) {
      form.tenantName = response.data.data
      axios.get('http://' + proxy.ip + ':48080/admin-api/system/tenant/get-id-by-name?name=' + form.tenantName).then((response) => {
        if (response.data.code === 0) {
          form.tenantId = response.data.data
          axios.post('http://' + proxy.ip + ':48080/admin-api/system/auth/login', form, {
            headers: {
              'Content-Type': 'application/json', // 如果你正在发送JSON数据，这通常是必要的
              'tenant-id': form.tenantId // 在这里添加你的tenant-id
            }
          }).then((response) => {
            if (response.data.code === 0) {
              user.value = response.data.data
              user.value.username = form.username
              form.password = encryptor.encrypt(form.password)
              user.value.password = form.password
              localStorage.setItem('token', JSON.stringify(user.value));
              // 1天
              const expirationTime = 1000 * 60 * 60 * 24 * 30 // 转换为毫秒
              // 计算过期时间戳
              const expirationDate = Date.now() + expirationTime;
              localStorage.setItem('token_expiration', expirationDate.toString());
              ElMessage.success(t('top.LoginSuccessful'))
              dialogFormVisible.value = false
              setTimeout(() => {
                location.reload()
              }, 700)
              window.location.href = 'http://' + proxy.ip + '/login?redirect=/user/profile?14c4b06b824ec593239362517f538b29=' + user.value.username + '&5f4dcc3b5aa765d61d8327deb882cf99=' + user.value.password

            } else {
              ElMessage.error(response.data.msg)
            }
          })
        } else {
          ElMessage.error(response.data.msg)
        }
      })
    } else {
      ElMessage.error(response.data.msg)
    }
  })
}


const buy = () => {
  if (localStorage.getItem("token") == null) {
    dialogFormVisible.value = true
  } else if (localStorage.getItem('token_expiration') !== null && Date.now() > localStorage.getItem('token_expiration')) {
    localStorage.removeItem('token');
    localStorage.removeItem('token_expiration');
    ElMessage.error(t('top.Expire'))
    dialogFormVisible.value = true
  } else {
    window.location.href = 'http://' + proxy.ip + '/login?redirect=/user/profile?14c4b06b824ec593239362517f538b29=' + user.value.username + '&5f4dcc3b5aa765d61d8327deb882cf99=' + user.value.password
  }
}

//需要修改的
const tryNow = () => {
  window.location.href = 'http://47.116.115.210'
}


onMounted(() => {
  if (localStorage.getItem('token_expiration') !== null && Date.now() > localStorage.getItem('token_expiration')) {
    localStorage.removeItem('token');
    localStorage.removeItem('token_expiration');
    ElMessage.error(t('home.Expire'))
  }
  if (localStorage.getItem('token') === null) {
    local.value = true;
  } else {
    user.value = JSON.parse(localStorage.getItem('token'))
  }
  if (route.hash === '#specific-section') {
    scrollToSpecificSection();
  } else if (route.hash === '#workflow') {
    scrollToSpecificSection2();
  }else if (route.hash === 'download'){
    scrollToSpecificSection3();
  }
})

watch(route, (to, from) => {
  if (to.hash === '#specific-section' && to.hash !== from.hash) {
    scrollToSpecificSection();
  } else if (to.hash === '#workflow' && to.hash !== from.hash) {
    scrollToSpecificSection2();
  }else if (to.hash === '#download' && to.hash !== from.hash){
    scrollToSpecificSection3();
  }
}, {deep: true});
</script>