export default {
    home: {
        LinYun: '麟云LinYun™平台',
        Brief1: 'BIM未来新起点，人人都能用好模型。',
        Brief2: '支持常用BIM模型数据导入与自动整合查阅，具备史无前例的兼顾轻量化、效果、信息数据的平台领先特征，',
        Brief3: '遵循简单、实用、高效的开发理念，致力于将BIM模型融入日常工作中。',
        Brief4: '以“人人都能用好模型”为目标，让更多人享受BIM工作流变革带来的真正便捷。',
        ReleaseDate: '专业版发布日期：2024年10月12日，欢迎下载体验！',
        FreeDownload: '免费下载',
        ExperienceFeedback: '体验反馈',
        UnityInDiversity:'合而为一，',
        QualityInDetails:'细节见品质。',
        LightWeight:'轻量化整合多专业、多格式模型，汇总多方BIM成果，各专业细节轻松把控。',
        SmoothToStart:'上手顺滑，',
        SimpleButExceptional:'简而不凡。',
        SupportLarge:'支持超大模型加载，极简的测量、剖切、视点记录、属性查看操作，丝滑上手，从此汇报交底轻松搞定。',
        CollaborationAndCommunication:'协作沟通',
        SeamlesslyAndEffortlessly:'一气呵成',
        InAnAdvance:'在快捷方便的高级协作模式中记录、跟踪问题，多方高效沟通，协作无间。',
        NextGeneration:'新一代',
        BIMCloudPlatform:'BIM云平台',
        TheWorld:'全球首款，数字化时代全新BIM平台',
        BetweenDifferentSoftware:'不同软件之间，',
        SeamlessConnection:'无缝畅连。',
        InstallTheLinYun:'安装LinYun插件，搭建专属工作流',
        DownloadTheLinYunRevitPlugin:'下载LinYunRevit插件',
        BuyNow:'即刻选购',
        ChooseYourPreferredPlan:'选择你心仪的方案',
        TrialVersion:'体验版',
        SuitableForBIMModelReporting:'适合BIM模型汇报',
        FreeToUse:'免费使用',
        TryNow:'即刻体验',
        TrialFeatures:'体验功能',
        NumberOfLocalProjects:'本地项目数量(1个)',
        MultipleSoftwarePlugins:'多种软件插件',
        StorageCapacity:'空间容量(限制)',
        ViewpointFunction:'视点功能',
        SlicingFunction:'剖切功能',
        MeasurementFunction:'测量功能',
        DirectoryTreeFunction:'目录树功能',
        AttributeFunction:'属性功能',
        ProfessionalVersion:'专业版',
        SuitableForPersonalProjects:'适合个人项目使用',
        Quarter:'季度',
        ClickToUpgrade:'点击升级',
        ProfessionalFeatures:'专业功能',
        NumberOfLocalProjectsProfessional:'本地项目数量(不限)',
        StorageCapacityProfessional:'空间容量(不限)',
        IssueCollaboration:'问题协作(精简模式)',
        ProjectDataMigration:'项目数据迁移',
        ProfessionalTechnicalSupport:'专业技术支持',
        IncludesAllTrialVersionFeatures:'包含所有体验版功能',
        ProjectVersion:'项目版',
        SuitableForSingleProjectUse:'适合单项目使用',
        Year:'年',
        ComingSoon:'敬请期待',
        BasicConfiguration:'基础配置',
        NumberOfCloudProjects:'云项目数量(1个)',
        NumberOfAccounts:'账号数量(5个)',
        CloudStorageCapacity:'云空间容量(2GB)',
        CrossProjectCollaboration:'跨项目协作',
        IssueCollaborationProject:'问题协作(精简模式)',
        CloudBasedModel:'模型云端分享同步',
        CloudCompression:'十倍云压缩',
        TechnicalSupport:'24小时技术支持',
        IncludesAllProfessionalVersionFeatures:'包含所有专业版功能',
        EnterpriseVersion:'企业版',
        SuitableForProjectsOfVariousEnterpriseScales:'适合企业不同规模项目使用',
        ContactUs:'欢迎咨询',
        ProfessionalConfiguration:'专业配置',
        WeChatConsultation:'微信咨询',
        NumberOfCloudProjectsEnterprise:'云项目数量(5个)',
        NumberOfAccountsEnterprise:'账号数量(10个)',
        CloudStorageCapacityEnterprise:'云空间容量(5*2GB)',
        CrossProjectCollaborationEnterprise:'跨项目协作',
        IssueCollaborationEnterprise:'问题协作(专业模式)',
        CloudBasedModelEnterprise:'模型云端分享同步',
        CloudCompressionEnterprise:'十倍云压缩',
        EnterpriseCustomLOGO:'企业专属LOGO',
        SupportsPaidUpgradesForAccountsProjects:'支持付费升级账号、项目',
        SupportsPaidCustomIntegration:'支持付费定制集成',
        TechnicalSupportEnterprise:'24小时技术支持',
        IncludesAllReleasedFeatures:'包含所有已发布功能',
        Expire:'登录过期，请重新登录。',
        DynamicRendering:'动态渲染',
        BasedOnDynamic:'基于动态全局光照技术，无需硬件光线追踪支持，也可以支持实时的全动态GI，对室内外场景均可以达到较好的细节质量与性能的平衡，实现高品质动态光照。',
        PowerfulCompatibility:'强大兼容性',
        UsingANewly:'使用全新自研插件，整合常用BIM软件与三维建模软件的模型及信息，支持多用户同时上传，不再为项目模型多而杂担忧。',
        HighQualityEffects:"高品质效果",
        WithSimpleMaterial:'只需在BIM、三维软件中简单设置材质，即可一键随模型导入，实现远超市面上现有BIM平台的渲染效果，让空间充满张力，带来全新的视觉体验。',
        SupportForLargeModels:'超大模型支持',
        NewGraphicsRendering:'全新的图形渲染技术和优化算法，支持高效处理大型模型数据，可承载大模型的快速加载、展示、剖切等功能；高性能电脑最高能够承载100万方大型机场项目的运行。',
        ModelLightweight:'模型轻量化',
        SupportsModelLightweight:'支持模型轻量化技术，自研算法可以对模型进行优化以减小其体积和计算资源的消耗，加快渲染速度，降低硬件需求。',
        SmoothUserExperience:'上手顺滑',
        WithAMinimalist:'在极简的软件界面中，只需几分钟便可由经典快捷的操作上手，软件学习不再成为负担。',
        InformationModel:'信息模型',
        SupportsTheIntegrationOf:'支持模型构件属性与参数信息集成，实现云平台快速查阅，帮助您更好地使用模型。',
        FlexibleIntegration:'自由集成',
        IndependentlyDeveloped:'由国内团队自主开发，更加适应国内BIM团队市场需求，未来可灵活集成到第三方平台。',
        img:'china.png',
        img2:'linYun4_C.png',
    },
    top: {
        Product:'产品',
        Workflow :'工作流',
        Tutorials :'教程',
        Pricing :'价格',
        Download:'下载',
        Login:'登录',
        Register:'注册',
        AccountLogin:'账号登录',
        Honorific:'尊敬的',
        Username:'账号:',
        Password:'密码:',
        Affirm:'确认',
        Cancel:'取消',
        Logout:'退出',
        GoBackstage:'进入后台',
        LoginSuccessful:'登录成功',
        LogoutSuccessful:'退出成功',
        ExitReminder:'退出提醒',
        ExitReminderText:'确定要退出账号吗?',
        CancelLogout:'取消退出',
        Expire:'登录过期，请重新登录。'
    },
    download: {
        Title: '麟云平台安装包与插件',
        NecessaryPlugIns: '必要的插件',
        DownloadLinYunRevitPlugin: '下载LinYun-Revit插件',
        DownloadSketchUpPlugin: '下载SketchUp插件',
        DownloadRhinoPlugin: '下载Rhino插件',
        VersionHistory: '版本历史记录',
    },
    download300:{
        DownloadV300:'下载V3.0.0',
        LatestVersion:'最新版本',
        UpdateContent:'更新内容',
        October122024:'2024年10月12日',
        PlatformClient:'平台客户端',
        NewRealistic:'1.新增真实动态环境天空（需在设置中主动开启）；',
        QuickRendering:'2.新增Revit材质快速呈现逼真材质效果；',
        Significant:'3.模型Mesh加载速度大幅度提升；',
        Optimization:'4.模型数据轻量化算法优化，压缩效率进一步提升；',
        MultiEnd:'5.多端视点同步功能修复；',
        QuickUpload:'6.3D模式下“快提”“快看”功能模块正式上线；',
        FilterDirectory:'7.问题高级协作模式中，问题的过滤目录功能正式上线；',
        RevitModel:'8.新增Revit模型材质可按着色或真实模式导出；',
        NewLighting:'9.新增光照时间调节功能；',
        EditingAndUpdating:'10.新增问题高级协作模式中，“问题标记”的编辑和更新功能；',
        NewNetwork:'11.新增网络状态判断标识显示（灰色、黄色、绿色）；',
        OptimizedUI:'12.优化问题高级协作模式下“专业”标签的UI设计；',
        Enhanced:'13.提升“普通”级别渲染显示效果；',
        Fixed:'14.修复特定情况下偶发的玻璃渲染异常；',
        OtherUI:'15.其它UI优化与已知BUG修复；',


    },
    download252:{
        DownloadV252Preview:'下载V2.5.2_Preview',
        StableVersion:'稳定版本',
        UpdateContent:'更新内容',
        August92024:'2024年8月9日',
        PlatformClient:'平台客户端',
        SimpleAndQuick:'1.简单快速提问与问题列表功能上线；',
        OptimizeLarge:'2.优化大模型数据支持(以往版本生成的项目数据无法复用',
        NeedTo:'需重新执行导入)；',
        RectangleBox:'3.三维标注矩形框与文字功能；',
        LocalIssue:'4.本地问题导出与导入功能；',
        CustomizeTheReduction:'5.导入时自定义设置减面；',
        ProblemFilterPreset:'6.问题过滤器预设测试功能；',
        ModelSubfileUninstall:'7.模型子文件卸载、删除测试功能；',
        OtherUI:'8.其它UI优化与已知BUG修复；',
    },
    download212:{
        DownloadV212Preview:'下载V2.1.2_Preview',
        StableVersion:'稳定版本',
        UpdateContent:'更新内容',
        July12024: '2024年7月1日',
        PlatformClient:'平台客户端',
        UIOptimization:'1.问题协作界面的UI优化调整。',
        EachAccountCan:'2.每个账号仅支持同时在线单个设备登录使用；支持账户离',
        WithdrawalLine:"线工作模式；已支持欧美用户注册登录使用。",
        TheModelUpdate:"3.打开项目时模型更新机制完善。",
        FixedACrash:'4.修复了大体量模型导入时崩溃问题。',
        OptimizeTheLocal:'5.本地文件存储方式优化。',
        OtherBUGFixes:'6.其他BUG修复及稳定性调整。',
    },
    download200:{
        DownloadV200Preview:'下载V2.0.0_Preview',
        StableVersion:'稳定版本',
        UpdateContent:'更新内容',
        June72024:'2024年6月7日',
        PlatformClient:'平台客户端',
        AddedANew:'1.在更多的场景中加入了新Logo图标。',
        UseANewUi:'2.使用全新的ui界面。',
        DesignANewRevitPlugin:'3.设计全新的revit插件。',
        AddedTheCloudProject:'4.新增了云项目。',
        AddedSharingFunction:'5.新增了分享功能。',
        AddedTheSynchronizationFunction:'6.新增了同步功能。',
        AddedIssueCollaboration:'7.新增了问题协作功能。',
        OptimizeTheImport:'8.优化DS文件的导入效率。',
        OptimizeTheResourceUsage:'9.优化导入DS文件时的资源占用率。',
        AddFunctionsSuchAsHiding:'10.添加场景内右键菜单隐藏、隔离、透明度等功能。',
        AddedTheHiding:'11.新增目录树中隐藏、隔离等功能。',
        AdjustedTheFunction:'12.调整了删除本地项目及云项目功能。',
        WebPageSide:'网页端',
        AddAccountRegistrationFunction:'1.新增注册账号功能。',
        PublishAPrice:'2.发布价格选购页面。',
    },
    download110:{
        DownloadV110Beta:'下载V1.1.0beta',
        StableVersion:'稳定版本',
        UpdateContent:'更新内容',
        April102024:'2024年4月10日',
        PlatformClient:'平台客户端',
        UseTheNewLogoIcon:'1.使用全新Logo图标。',
        AddTheDirectory:'2.新增目录树子功能。',
        ModifyTheProperty:'3.修改属性数据查询方式与性能优化。',
        OptimizeTheView:'4.优化视点跳转模式与速度。',
        OptimizeUI:'5.优化UI和交互体验。',
        ModifySomeBugs:'6.修改若干BUG。',
    },
    download101:{
        DownloadV101Beta:'下载V1.0.1beta',
        StableVersion:'稳定版本',
        UpdateContent:'更新内容',
        January312024:'2024年1月31日',
        PlatformClient:'平台客户端',
        UpdateTheInstallation:'1.更新安装路径。',
        LanguageSelection:'2.语言选择（中英文）',
        CloseSseLink:'3.关闭sse链接（关闭程序奔溃bug修复）。',
        ImportFileLog:'4.导入文件log修改（防止c盘爆内存）。',
        AdjustTheUiSettings:'5.ui界面设置部分调整。',
        BUGRepair:'6.BUG修复及稳定性调整。',
    },
    download100:{
        DownloadV100Beta:'下载V1.0.0beta',
        StableVersion:'稳定版本',
        UpdateContent:'更新内容',
        January62024:'2024年1月6日',
        ThePublicBeta:'正式发布了公测版的beta版。',

    }
};