import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import {createI18n} from 'vue-i18n';
import en from "@/lang/en";
import zh from "@/lang/zh-CN";

// 导入翻译文件
//需要修改的
const savedLocale = localStorage.getItem('locale') || 'zh-CN';
// const savedLocale = localStorage.getItem('locale') || 'en';


const i18n = createI18n({
    locale: savedLocale, // 设置默认语言
    messages: {
        zh,
        en
    }
});

// createApp(App).use(ElementPlus).use(store).use(router).use(i18n).mount('#app')
// 创建 Vue 应用实例
const app = createApp(App);

// 注册 Element Plus
app.use(ElementPlus);

// 注册 Vuex store
app.use(store);

// 注册 Vue Router
app.use(router);

// 注册 i18n
app.use(i18n);

//需要修改的
// 注册全局变量
// app.config.globalProperties.ip = 'localhost';
// app.config.globalProperties.ip = '47.89.229.58';
app.config.globalProperties.ip = '47.116.115.210';

// 挂载应用
app.mount('#app');