<style scoped>

.link {
  color: white;
  text-decoration: none;
  font-size: 15px;
}

.link:hover {
  color: skyblue;
  cursor: pointer;
}

@keyframes zoom {
  from {
    transform: scale(1); /* 起始时大小不变 */
  }
  to {
    transform: scale(1.1); /* 结束时放大到1.5倍 */
  }
}

.logout:hover {
  cursor: pointer;
}


</style>

<template>

  <!--  顶部头-->
  <div style="background-color:#FF2727;width: 100%;height: 50px;border-radius: 5px 5px 0 0;">
    <el-row>

      <el-col :span="9">
        <el-row style="margin-top: 15px;margin-left: 50%">

          <el-col :span="4">
            <router-link to="/" class="link">
              {{ t('top.Product') }}
            </router-link>
          </el-col>

          <el-col :span="4">
            <span class="link" @click="goToBPageSection2">
              {{ t('top.Workflow') }}
            </span>
          </el-col>

          <el-col :span="4">
<!--需要修改的-->
<!--            国内-->
            <a href="https://linyunbim.cowtransfer.com/s/9d9697b442874e "
               class="link">{{ t('top.Tutorials') }}</a>

<!--国外-->
<!--            <a href="https://drive.google.com/file/d/1GB3Uze-iNAw4PAEC9YiqtEIVHgda7SJP/view?usp=sharing"-->
<!--               class="link">{{ t('top.Tutorials') }}</a>-->

          </el-col>

          <el-col :span="4">
            <span class="link" @click="goToBPageSection">
              {{ t('top.Pricing') }}
            </span>
          </el-col>

          <el-col :span="4">
                        <router-link to="/download" class="link">
                          {{t('top.Download')}}
                        </router-link>
          </el-col>

        </el-row>
      </el-col>

      <el-col :span="8" style="margin-top: 15px">
        <div class="logo-container" style="display: flex; align-items: center;margin-left: 40%;margin-top: -1.5%">
          <router-link to="/" class="logo-link" style="text-decoration: none; color: white;">
            <img src="../../public/logo_white44-01-01-01.png" alt="麟云平台Logo" style="margin-right: 10px;">
          </router-link>
          <router-link to="/" class="logo-link" style="text-decoration: none; color: white;">
            <span>麟云平台</span>
          </router-link>

        </div>

      </el-col>

      <el-col :span="5" style="margin-top: 15px;color: white">
        <!--        <el-button v-if="local" plain @click="dialogFormVisible = true"-->
        <!--                   style="background-color: #FF2727;color: white;border: none;font-size: 16px">-->
        <!--          {{t('top.Login')}}-->
        <!--        </el-button>-->

<!--        需要修改的，国外不需要以下两行-->
        <a href="http://47.116.115.210/login?register" style="text-decoration: none"><span class="link" style="margin-left: -20px" v-if="local">{{ t('top.Register') }}</span></a>
        <span @click="dialogFormVisible = true" class="link" style="margin-left: 10px" v-if="local">{{ t('top.Login') }}</span>


        <el-dialog v-model="dialogFormVisible" :title="t('top.AccountLogin')" width="450" style="margin-top: 20%;text-align: center">
          <el-form :model="form">
            <el-form-item :label="t('top.Username')" :label-width="formLabelWidth" style="margin-left: 8%;margin-top: 10px">
              <el-input style="width: 300px" v-model="form.username" autocomplete="off"/>
            </el-form-item>

            <el-form-item :label="t('top.Password')" :label-width="formLabelWidth" style="margin-left: 8%">
              <el-input style="width: 300px" type="password" v-model="form.password" autocomplete="off"/>
            </el-form-item>
          </el-form>

          <template #footer>
            <div class="dialog-footer">
              <el-button @click="dialogFormVisible = false">{{ t('top.Cancel') }}</el-button>
              <el-button type="primary" @click="handleLogin">
                {{ t('top.Login') }}
              </el-button>
            </div>
          </template>
        </el-dialog>
        <span v-if="!local">{{ t('top.Honorific') }} : {{ user.nickname }}</span>
        <span v-if="!local" style="margin-left: 20px" @click="logout" class="link">{{ t('top.Logout') }}</span>
        <span v-if="!local" style="margin-left: 20px" @click="logBack" class="link">{{ t('top.GoBackstage') }}</span>

        <span @click="changeLanguage('zh-CN')" class="link" :class="{ active: currentLocale === 'zh-CN' }"
              style="margin-left: 30px">简体中文</span>
        <span @click="changeLanguage('en')" class="link" :class="{ active: currentLocale === 'en' }"
              style="margin-left: 10px">English</span>
      </el-col>

    </el-row>
  </div>

</template>

<script lang="js" setup>
import {getCurrentInstance, onMounted, reactive, ref} from 'vue';
import axios from "axios";
import {ElMessage, ElMessageBox} from "element-plus";
import JSEncrypt from "jsencrypt";
import {useRouter} from 'vue-router';

const {proxy} = getCurrentInstance();

import {useI18n} from 'vue-i18n';

// 使用 useI18n 钩子
const {t, locale} = useI18n();

// 当前语言的响应式变量
const currentLocale = ref(locale.value);

// 切换语言的函数
const changeLanguage = (lang) => {
  locale.value = lang;
  currentLocale.value = lang;
  // 将新的语言选择保存到 localStorage
  localStorage.setItem('locale', lang);
};

const router = useRouter();
const dialogFormVisible = ref(false)
const formLabelWidth = '50px'

const form = reactive({
  username: '',
  password: '',
  nickName: '',
  tenantName: '',
  tenantId: '',
})


const handleLogin = () => {
  axios.post('http://' + proxy.ip + ':48080/admin-api/system/auth/getTenant?username=' + form.username).then((response) => {
    if (response.data.code === 0) {
      form.tenantName = response.data.data
      axios.get('http://' + proxy.ip + ':48080/admin-api/system/tenant/get-id-by-name?name=' + form.tenantName).then((response) => {
        if (response.data.code === 0) {
          form.tenantId = response.data.data
          axios.post('http://' + proxy.ip + ':48080/admin-api/system/auth/login', form, {
            headers: {
              'Content-Type': 'application/json', // 如果你正在发送JSON数据，这通常是必要的
              'tenant-id': form.tenantId // 在这里添加你的tenant-id
            }
          }).then((response) => {
            if (response.data.code === 0) {
              user.value = response.data.data
              axios.get('http://' + proxy.ip + ':48080/admin-api/system/auth/getNickname?userId=' + user.value.userId).then((response) => {
                if (response.data.code === 0) {
                  user.value.nickname = response.data.data
                  user.value.username = form.username
                  form.password = encryptor.encrypt(form.password)
                  user.value.password = form.password
                  console.log(user.value.nickname)
                  localStorage.setItem('token', JSON.stringify(user.value));
                  // 30天
                  const expirationTime = 1000 * 60 * 60 * 24 * 30  // 转换为毫秒
                  // 计算过期时间戳
                  const expirationDate = Date.now() + expirationTime;
                  localStorage.setItem('token_expiration', expirationDate.toString());
                  ElMessage.success(t('top.LoginSuccessful'))
                  dialogFormVisible.value = false
                  setTimeout(() => {
                    location.reload()
                  }, 700)
                }
              })
            } else {
              ElMessage.error(response.data.msg)
            }
          })
        } else {
          ElMessage.error(response.data.msg)
        }
      })
    } else {
      ElMessage.error(response.data.msg)
    }
  })
}

const logout = () => {
  ElMessageBox.confirm(
      t('top.ExitReminderText'),
      t('top.ExitReminder'),
      {
        confirmButtonText: t('top.Affirm'),
        cancelButtonText: t('top.Cancel'),
        type: 'warning',
      }
  )
      .then(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('token_expiration');
        ElMessage.success(t('top.LogoutSuccessful'))
        setTimeout(() => {
          location.reload()
        }, 700)
      })
      .catch(() => {
        ElMessage({
          type: 'info',
          message: t('top.CancelLogout'),
        })
      })
}

const publicKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAn8wru5QjuYhj7pFNFdJroP5JmLQHbciRiIXrjlA4mfk+DFhJ8sPiiKQmZxDjfjip++tcChpKfhIqGrlVe3lJiojfV1wUaB' +
    'nf8GYc0+gJQuy1u94VYtwYC7Rdv/RIhMil2yiIsD+QX2c/gjEXxTXqO87KSNUdFJKCxHLUE3MY+6QAcV2WV4cp/z/x+BhYSZLG6+o8hKjyLtDMfTuJwZj2398QTcZ/6WlZWg2ScKUUxZX+BUj8GujT2jQk' +
    'WDMbvtLuBXT7KDR5OXo0cZNULAH9waaBd3rezzZCp8behKGPBeBbi2/CJRKcwDcJjzB51vrXwAW78mOvQ4m2UwS1culwcwIDAQAB'

const encryptor = new JSEncrypt();
encryptor.setPublicKey(publicKey);

const logBack = () => {
  window.location.href = 'http://' + proxy.ip + '/login?redirect=/index?14c4b06b824ec593239362517f538b29=' + user.value.username + '&5f4dcc3b5aa765d61d8327deb882cf99=' + user.value.password
}

const price = ref(null);

//点击价格下滑
const goToBPageSection = () => {
  router.push({path: '/', hash: '#specific-section'});
}

//工作流下滑
const goToBPageSection2 = () => {
  router.push({path: '/', hash: '#workflow'});
}

const goToBPageSection3 = () => {
  router.push({path: '/', hash: '#download'});
}

const local = ref();
const user = ref({
  userId: '',
  accessToken: '',
  username: '',
  password: '',
  tenantId: '',
  nickname: '',
  version: '',
})
onMounted(() => {

  if (localStorage.getItem('token_expiration') !== null && Date.now() > localStorage.getItem('token_expiration')) {
    localStorage.removeItem('token');
    localStorage.removeItem('token_expiration');
    ElMessage.error(t('top.Expire'))
  }
  if (localStorage.getItem('token') === null) {
    local.value = true;
  } else {
    user.value = JSON.parse(localStorage.getItem('token'))
  }
  const savedLocale = localStorage.getItem('locale');
  if (savedLocale) {
    locale.value = savedLocale;
    currentLocale.value = savedLocale;
  }


})
</script>